import React, { useState, useEffect } from "react";
import useLocalStorage from "./hooks/useLocalStorage";

import axios from "axios";
import "./Episodes.css";

const channelID = "UCyUWgAVvyA0_RU0XZ2qON7A";
const result = "9";
const finalURL = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelID}&maxResults=${result}&order=date&key=${process.env.REACT_APP_API_KEY}`;

function Home() {
  const [videos, setVideos] = useLocalStorage('videos',[]);

  const getVideos = () => {
    axios.get(finalURL).then((response) => {
      const ids = response.data.items.map((obj) => obj.id.videoId);
      const prefix = "https://www.youtube.com/embed/";
      const url = ids.map((id) => prefix + id);
      console.log(url);
      setVideos(url);
    });
  };

  useEffect(() => getVideos(), []);

  return (
    <div>
      <div className="header">
        <h2>Check out our other videos!</h2>
      </div>
      <div className="container">
        {videos.map((video) => {
          return (
            <div className="episodes_container">
              <iframe className="video_container"
                src={video}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      </div>
      <div className="view-more">
        <button>
          <a
            href="https://www.youtube.com/channel/UCyUWgAVvyA0_RU0XZ2qON7A"
            target="_blank"
            rel="noreferrer noopener"
          >
            View more
          </a>
        </button>
      </div>
    </div>
  );
}

export default Home;
